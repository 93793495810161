import Seo from '@/components/seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { fetchHandshake } from '@/services/handshake';
import { getHomeJson } from '@/services/home';
import React from 'react';
import { getTabsData, renderHomeSection } from '@/utils/home';
import { getDevicesKeys } from '@/utils/global';

const Index = ({ home, isMobile }) => {

  return (
    <>
      <Seo />
      <section className='container mx-auto *:my-5 *:sm:my-7 '>
        {home?.length
          ? home.map((section, i) =>
              section?.active ? (
                <React.Fragment key={i}>
                  {renderHomeSection(section.key, section, isMobile)}
                </React.Fragment>
              ) : null
            )
          : null}
      </section>
    </>
  );
};

export default Index;

export async function getServerSideProps({ locale, req, res }) {
   const userAgent = req.headers['user-agent'] || '';
   const isMobile =
     /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
       userAgent
     );

 const {active, position} = getDevicesKeys(isMobile)
  // get handshake from cookies or generate new handshake
  const handshake = await fetchHandshake({ req, res, locale });

  //  get home page structure json
  const { data, error } = await getHomeJson(handshake);
  if (error) {
    return {
      props: {},
    };
  }

  const homeJson = Array.isArray(data?.data) ? data?.data : [];

  const mainBanners =
    homeJson?.find((item) => item.key === 'main-banners' && item[active]) || {};
  const mainSliders =
    homeJson?.find((item) => item.key === 'main-sliders' && item[active]) || {};
  const sideBanners =
    homeJson?.find((item) => item.key === 'side-banners' && item[active]) || {};

  const firstSection =
    mainBanners && mainSliders
      ? {
          position: 1,
          key: 'first-section',
          active: true,
          main_banners: mainBanners,
          main_sliders: mainSliders,
          side_banners: sideBanners || {}
        }
      : {};

  const static_parts = [...homeJson, firstSection]?.filter(
    (item) =>
      ![
        'products-list',
        'products-grid',
        'flash-sale',
        'main-banners',
        'main-sliders',
      ].includes(item.key)
  );

  // handle sections have tabs
  const tabs_parts = homeJson?.filter(
    (item) =>
      item.key === 'products-list' ||
      item.key === 'products-grid' ||
      item.key === 'flash-sale'
  );

  const allTabs = await Promise.all(
    tabs_parts?.map((tab) => getTabsData(handshake, tab))
  );

  const home = [
    ...(Array.isArray(static_parts) ? static_parts : []),
    ...(Array.isArray(allTabs) ? allTabs : []),
  ]
    .sort((a, b) => +a?.[position] - +b?.[position])
    ?.filter((tab) => tab?.[active]);

  return {
    props: {
      // home2: { mainBanners, mainSliders },
      isMobile,
      home,
      ...(await serverSideTranslations(locale, ['common', 'home'])),
    },
  };
}
