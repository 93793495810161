import { useRouter } from 'next/router';
import Image from '../Image';
import HomeBannerSwiper from '../global/swiper/HomeBannerSwiper';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import Link from 'next/link';
import { renderImageHandler } from '@/helpers/common/renderImage';
import { getDevicesKeys } from '@/utils/global';

const MainBanners = ({ data, isMobile }) => {
  const { locale } = useRouter();
  const {active, position} = getDevicesKeys(isMobile)

  const activeMainBanners = data?.main_banners?.value?.filter(
    (item) => item?.[active]
  );
  const activeMainSliders = data?.main_sliders?.value
    ?.filter((item) => item?.[active])
    ?.toSorted((a, b) => +a?.[position] - b?.[position]);
  const sideBanner = data?.side_banners.value?.at(0)

  const activeMainBanner_imageSrc = renderImageHandler(sideBanner, locale);

  return (
    <div className='grid grid-cols-12 items-start gap-5 sm:my-5'>
      <Link
        href={handleLinkByType(
          sideBanner?.url,
          sideBanner?.type
        )}
        className='h-full relative col-span-full max-sm:hidden sm:col-span-4 md:col-span-3 lg:col-span-2'
      >
        <Image
          loading='eager'
          featchPriority='high'
          src={activeMainBanner_imageSrc}
          className='h-full max-h-[504px] w-full rounded-xl object-cover'
          alt='Banner 1'
        />
        <div className='absolute left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-around rounded-xl bg-black bg-opacity-25'>
          <div className='text-center text-white'>
            {sideBanner?.[`title-${locale}`] && (
              <p className='mb-3 text-md font-medium'>
                {sideBanner?.[`title-${locale}`]}
              </p>
            )}
            {sideBanner?.[`subtitle-${locale}`] && (
              <div className='mb-3 text-xl font-medium'>
                {sideBanner?.[`subtitle-${locale}`]}
              </div>
            )}
            {sideBanner?.[`description-${locale}`] && (
              <span className='text-sm font-medium'>
                {sideBanner?.[`description-${locale}`]}
              </span>
            )}
          </div>
          {sideBanner?.[`button-${locale}`] && (
            <Link
              href={handleLinkByType(
                sideBanner?.url,
                sideBanner?.type
              )}
              className='inline-block rounded-md bg-white hover:bg-slate-50 p-3 text-sm font-medium text-primary'
            >
              {sideBanner?.[`button-${locale}`]}
            </Link>
          )}
        </div>
      </Link>
      <div className='col-span-full grid grid-cols-3 gap-5 sm:col-span-8 md:col-span-9 lg:col-span-10'>
        <div className='relative col-span-full'>
          <HomeBannerSwiper sliders={activeMainSliders} />
        </div>
        <div className='col-span-full flex grid-cols-3 gap-4 max-sm:overflow-x-auto sm:grid gray-scroll'>
          {activeMainBanners?.map((banner, i) => {
            const imgSrc = renderImageHandler(banner, locale);

            return (
              <Link
                key={i}
                href={handleLinkByType(banner?.url, banner?.type)}
                className='relative max-sm:min-w-64 max-sm:max-w-64'
              >
                <Image
                  loading='eager'
                  src={imgSrc}
                  className='h-36 w-full rounded-xl object-cover sm:h-[154px]'
                  alt='Banner 1'
                />
                <div className='absolute start-0 top-0 z-50 h-full w-full rounded-xl bg-black bg-opacity-25'>
                  <div className='ps-4 pt-5 text-white'>
                    <p className='mb-3 text-base'>
                      {banner?.[`title-${locale}`]}
                    </p>
                    <p className='text-xl font-medium'>
                      {banner?.[`subtitle-${locale}`]}
                    </p>
                    <p className='text-lg font-medium'>
                      {banner?.[`description-${locale}`]}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainBanners;
